<template>
  <div class="admin-data">
    <div class="container">
      <div class="admin-data__header">
        <div class="admin-data__data">
          <div class="admin-data__data-title">
            Всего веществ:
          </div>
          <div v-if="materials" class="admin-data__data-value" >
            {{ materials.paginate.total }}
          </div>
        </div>
        <ButtonElement text="Добавить вещество" fill="empty" link="/substances/new" />
      </div>
      <div v-if="materials" class="admin-data__table">
        <el-table :data="materials.items" style="width: 100%">
          <el-table-column prop="name" label="Название" />
          <el-table-column label="Дата регистрации">
            <template #default="scope">
              <div :key="scope.row.id">{{ fixedDate(scope.row.created_at) }}</div> 
            </template>
          </el-table-column>
          <el-table-column width="50">
            <template #default="scope">
              <el-dropdown placement="bottom-end" trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  <img src="../assets/icons/icon-more.svg" alt="">
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="{ name: 'edit', id: scope.row.id }">
                      Редактировать
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination 
        v-if="materials && materials.paginate.total > 1" 
        :pagesInfo="materials.paginate" 
        @changePage="getSubstances($route.query)"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '../components/elements/Pagination'
import ButtonElement from '../components/elements/ButtonElement'
import { DateParser } from '@/mixin/date_parser'

export default {
  name: 'Substances',
  mixins: [DateParser],
  components: {
    Pagination,
    ButtonElement,
  },
  data() {
    return {
      materials: null,
    }
  },
  mounted() {
    this.getSubstances(this.$route.query)
  },
  methods: {
    handleCommand(command) {
      if (command.name === 'edit') {
        this.$store.commit('Substances/setData', {label:"editSubstance", data: command.id} )
        this.$router.push(`/substances/${command.id}`)
      }
    },
    getSubstances(params) {
    this.$store.dispatch('Substances/getAdminSubstances', params)
      .then(resp => {
        this.materials = resp.data.data
      })
      .catch(err => {
        console.log(err.response.data.errors)
      })
    }
  }
}
</script>
